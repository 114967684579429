*
{
    color: #343434 !important;
    background: transparent !important;
    box-shadow: none !important; 
    text-shadow: none !important;
}

a,
a:visited
{
    text-decoration: underline;
}

pre,
blockquote
{
    border: 1px solid #999;

    page-break-inside: avoid;
}

thead
{
    display: table-header-group;
}

tr,
img
{
    page-break-inside: avoid;
}

img
{
    max-width: 100% !important;
}

p,
h2,
h3
{
    orphans: 3;
    widows: 3;
}

h2,
h3
{
    page-break-after: avoid;
}

select
{
    background: #fff !important;
}

.entry-content
{
    width: 100%;
}
